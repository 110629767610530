import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Card, PolycardContext } from '@polycard/card';
import { SnappedCollection } from '@mshops-components-library/snapped-collection';
import { TYPES } from '../../utils/constants/shop/types';
import { isOffersCarousel, processUrl } from './utils';

const PolycardCarousel = ({
  polycards,
  shopType,
  device,
  viewMoreAction,
  polycardContext,
  siteId,
  isFreeCarousel,
  namespace,
  strategy,
  componentId,
  ...props
}) => {
  const notMLM = siteId !== 'MLM';
  const classPolycard = 'ui-ms-polycard-carousel';

  const formats = {
    thousandSeparator: notMLM ? '.' : ',',
    decimalSeparator: notMLM ? ',' : '.',
  };

  const isLandingDiscovery = shopType === TYPES.LANDING && strategy === 'discovery';

  const strategyPolycards = () => {
    const newPolycards = polycards.map((item) => {
      const newItem = {
        ...item,
        metadata: {
          ...item.metadata,
          ...processUrl(viewMoreAction.target, item.metadata.url_fragments, item.metadata.id),
        },
      };
      return newItem;
    });
    return newPolycards;
  };

  const polycardItems = !isLandingDiscovery ? polycards : strategyPolycards();
  const isOffferCarousel = isOffersCarousel(componentId);

  const classCarousel = classNames(namespace, classPolycard, {
    [`${classPolycard}--offers`]: isOffferCarousel,
  });

  return (
    <div className={classCarousel}>
      <PolycardContext
        contextValue={{
          ...polycardContext,
          type: 'grid-card',
          target: '_self',
        }}
        deviceType={device}
        withoutIcons
        {...formats}
      >
        <SnappedCollection
          {...props}
          eshopType={shopType}
          deviceType={device}
          view_more_action={viewMoreAction}
          isFreeCarousel={isFreeCarousel}
        >
          {polycardItems?.map((item) => (
            <Card key={item.metadata.id} polycard={item} />
          ))}
        </SnappedCollection>
      </PolycardContext>
    </div>
  );
};

PolycardCarousel.propTypes = {
  polycards: PropTypes.arrayOf(PropTypes.shape({})),
  seeElements: PropTypes.shape({}),
  shopType: PropTypes.string,
  device: PropTypes.string,
  viewMoreAction: PropTypes.shape({}),
  polycardContext: PropTypes.shape({}).isRequired,
  siteId: PropTypes.string.isRequired,
  isFreeCarousel: PropTypes.bool.isRequired,
  namespace: PropTypes.string,
  strategy: PropTypes.string,
  componentId: PropTypes.string.isRequired,
};

PolycardCarousel.defaultProps = {
  polycards: [],
  seeElements: {},
  shopType: '',
  device: '',
  viewMoreAction: {},
  namespace: 'mshops-recommendations-wrapper',
  strategy: '',
};

export default PolycardCarousel;
